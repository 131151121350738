export const FEATURE_ITEMS = [
  {
    description: '<a href="/energy/quote/form/step1/" class="underline font-bold">Compare Club</a> can help you find the cheapest electricity, solar, and gas rates within minutes.',
    hasLink: true,
    icon: {
      url:  '/icons/check-circle-dark.svg',
      alt:  'Checked'
    }
  },
  {
    description: 'You may also qualify for state-based energy rebates to bring your bills down even more.',
    icon: {
      url:  '/icons/check-circle-dark.svg',
      alt:  'Checked'
    }
  },
  {
    description: 'Then super-charge your savings with our energy reduction tips, or learn about installing a rooftop solar system.',
    icon: {
      url:  '/icons/check-circle-dark.svg',
      alt:  'Checked'
    }
  }
]

export const DOCUMENTS_TO_START = {
  subTitle:'If you choose to switch to a new energy provider, Compare Club’s expert team will take care of all your paperwork. But there’s a few things we might need from you:',
  documentItems: [
    {
      title: 'Recent energy bill',
      description: 'This will contain important information like your current energy usage, tariff rates, meter number, and account details.',
      icon: {
        url: '/icons/energy/icons/bill-circle-fill-blue-grey-bg.svg',
        width: 48,
        height: 48
      }
    },
    {
      title: 'Personal identification',
      description: 'A driver\'s licence, passport, or utility bill in your name may be required to verify your identity.',
      icon: {
        url: '/icons/energy/icons/id-circle-fill-green-grey-bg.svg',
        width: 48,
        height: 48
      }
    },
    {
      title: 'Bank account details',
      description: 'You\'ll need to provide your bank account details if you choose to set up direct debit payments with your new energy supplier.',
      icon: {
        url: '/icons/energy/icons/bank-card-circle-fill-yellow-grey-bg.svg',
        width: 48,
        height: 48
      }
    }
  ]
}

export const PARTNER_ITEMS = [
  {
    company: 'AGL.svg',
    dimensions: {
      width: 100,
      height: 34
    }
  },
  {
    company: 'ORIGIN.svg',
    dimensions: {
      width: 75,
      height: 44
    }
  },
  {
    company: 'Sumo.svg',
    dimensions: {
      width: 96,
      height: 44
    }
  },
  {
    company: 'ENGIE.svg',
    dimensions: {
      width: 111,
      height: 18
    }
  },
  {
    company: 'DODO.svg',
    dimensions: {
      width: 108,
      height: 40
    }
  },
  {
    company: 'OVO.svg',
    dimensions: {
      width: 90,
      height: 35
    }
  },
  {
    company: 'square-logos/ENERGY-AUSTRALIA.svg',
    dimensions: {
      width: 102,
      height: 35
    }
  },
  {
    company: 'square-logos/1st-energy.svg',
    dimensions: {
      width: 102,
      height: 35
    }
  }
]

export const TIPS_TO_SAVE = [
  {
    description: 'Choose appliances with high energy-efficiency ratings. They use less energy and will likely save you money in the long run.' 
  },
  {
    description: 'Inspect your doors and windows for air leaks and seal them with weatherstripping or caulking. This will help to reduce the need for heating or cooling.' 
  },
  {
    description: 'If you have an electric water heater, lower the temperature and insulate the tank to minimise heat loss and reduce the energy needed for reheating.' 
  },
  {
    description: 'Installing thermal curtains or blinds can also help to keep your home warmer in winter and cooler in summer without the need for electricity.' 
  },
  {
    description: 'Check that your fridge is set to between three to five degrees Celsius for optimal eco-friendly operation.' 
  },
  {
    description: 'Ask us about upgrading your household appliances to the most energy efficient option. You may be eligible for a rebate as part of the Federal Government Household Upgrade scheme'
  }
]

export const SWITCHING_PROVIDERS = {
  subTitle: 'Compare Club makes it easy to switch to a new energy provider. However, there are a few things you can supply to help us speed up the process: ',
  providers: [
    {
      title: 'Your latest energy bill',
      description: 'This will help the experts at Compare Club understand your current plan, tariffs, and energy usage.',
      icon: {
        url: '/icons/energy/icons/bill-blue.svg',
        width: 64,
        height: 64
      }
    },
    {
      title: 'Your current energy contract',
      description: 'If you have a copy of your current contract with your energy provider, we can help determine whether any exit fees may apply.',
      icon: {
        url: '/icons/energy/icons/contract-brown.svg',
        width: 64,
        height: 64
      }
    },
    {
      title: 'Driver’s licence or passport',
      description: 'One or the other may be required to set up an account with your new energy provider.',
      icon: {
        url: '/icons/energy/icons/licence-or-passport-blue.svg',
        width: 64,
        height: 64
      }
    },
    {
      title: 'Bank account details',
      description: 'Your account details will be handy if you want to set up direct debit payments on your new account.',
      icon: {
        url: '/icons/energy/icons/bank-details-blue.svg',
        width: 64,
        height: 64
      }
    }
  ]
}

export const KEYTERMS_ITEMS = [
  {
    title: 'Default market offer (DMO)',
    description: 'A government-imposed annual price cap on electricity prices in New South Wales, South Australia and South East Queensland to protect consumers from paying excessively high prices.'
  },
  {
    title: 'Market offer',
    description: 'An energy plan or contract that provides consumers with a range of choices and options tailored to their preferences and needs.'
  },
  {
    title: 'Standing offer',
    description: 'A default energy plan that is provided to consumers who have not selected a market contract with specific terms and conditions.'
  },
  {
    title: 'Peak tariff',
    description: 'Also known as a peak rate, it refers to higher rates that are charged for electricity usage during periods of high demand.'
  },
  {
    title: 'Off-peak tariff',
    description: 'Also known as an off-peak rate, it refers to lower rates that are charged for electricity usage during periods of low demand.'
  }
]

export const GREAT_DEAL_ITEMS = [
  {
    title: 'Switching To A Better Deal',
    url: '/energy/faqs-switching-providers/',
    icon: '/icons/energy/switch-deal.svg',
    iconDimensions: {
      width: 64,
      height: 64
    }
  },
  {
    title: 'Choosing A Plan When Moving House',
    url: '/energy/moving-house/',
    icon: '/icons/energy/choose-plan.svg',
    iconDimensions: {
      width: 64,
      height: 64
    }
  },
  {
    title: 'Reading Your Energy Bill: What To Look For',
    url: '/energy/electricity-charges/',
    icon: '/icons/energy/bill.svg',
    iconDimensions: {
      width: 64,
      height: 64
    }
  },
  {
    title: 'Know What You\'re Being Charged For',
    url: '/energy/electricity-pricing-structure/',
    icon: '/icons/energy/charges.svg',
    iconDimensions: {
      width: 64,
      height: 64
    }
  }
]

export const SAVING_PLAN_ITEMS = [
  {
    title: 'No more bill shock. Ever',
    description: 'Aussie households can be saving between $266 and $553 compared to the energy reference price.*',
    sectionImage: `${APP_ASSET_BASEURL}edge/static/images/products/energy/saving-plans/bill-shock.svg`,
    sectionImageDimensions: {
      width: 155,
      height: 180
    }
  },
  {
    title: 'It\'s simple to switch',
    description: 'Save time and money as we do the heavy lifting. Our expert team takes care of everything, from finding you the lowest possible price, to handling all your paperwork. We’ll even apply any relevant discounts you’re eligible for.',
    sectionImage: `${APP_ASSET_BASEURL}edge/static/images/products/energy/saving-plans/switch.svg`,
    sectionImageDimensions: {
      width: 161,
      height: 180
    }
  },
  {
    title: 'Helping thousands to save',
    description: 'We’ve been cutting energy bills across Australia since 2010. We’ll compare big brands and boutique energy providers to make sure you’re paying the lowest possible price on your gas and electricity.',
    sectionImage: `${APP_ASSET_BASEURL}edge/static/images/products/energy/saving-plans/save.svg`,
    sectionImageDimensions: {
      width: 194,
      height: 181
    }
  },
  {
    title: 'Free no-obligation quotes',
    description: 'We’re Australian based, free to use, there’s no hidden fees and our team takes care of your application. You\'re not obliged to switch, but we’re confident you’ll be able to shave hundreds of dollars off your energy bill with us.',
    sectionImage: `${APP_ASSET_BASEURL}edge/static/images/products/energy/saving-plans/free-quotes.svg`,
    sectionImageDimensions: {
      width: 210,
      height: 180
    }
  },
  {
    title: '10 day cooling off period',
    description: 'Changed your mind? Found a better price? No worries. You can cancel your new plan any time in the first ten days. Although we reckon you’ll be hard pushed to find a cheaper energy deal anywhere else.',
    sectionImage: `${APP_ASSET_BASEURL}edge/static/images/products/energy/saving-plans/10-day-off.svg`,
    sectionImageDimensions: {
      width: 192,
      height: 180
    }
  }
]

export const GUIDES_ITEMS = [
  {
    title: 'Top 10 energy companies in Australia in 2023',
    content: 'Should you go with a big brand or a small challenger? Read our guide to Australian\'s best energy providers.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/products/energy/guides/top-10-companies.png`,
    pageUrl: '/energy/top-10-companies/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  },
  {
    title: 'How to downsize your energy bill.',
    content: 'During winter, many of us struggle with gloomy days, heavy rainfall and flu season. But there\'s one more thing. Energy bills.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/products/energy/guides/downsize-bill.png`,
    pageUrl: '/energy/use-less-power-home/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  },
  {
    title: 'A quick guide to different electricity fees and charges',
    content: 'Feel lost with your electricity bills? Learn more about usage and supply charge with Compareclub.com.au.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/products/energy/guides/quick-guide.png`,
    pageUrl: '/energy/electricity-charges',
    imageDimensions: {
      width: 291,
      height: 202
    }
  }
]

export const FAQ_ITEMS = [
  {
    title: 'How to compare energy?',
    content: 'It’s easy – just get in touch with the experts at Compare Club. We’ll compare all the rates offered by our network of energy suppliers and different electricity and gas plans. And if you choose to switch to a new provider, we’ll even arrange the connection and send you any relevant information.'
  },
  {
    title: 'How much is gas compared to electricity?',
    content: 'While gas is typically cheaper per unit than electricity, a single unit of electricity generally contains more energy than a single unit of gas. That means you’ll likely use more units of gas than electricity to power the same appliance. How the final costs balance out really depends on the gas and electricity rates you’re paying.'
  },
  {
    title: 'How does Compare Club Energy work?',
    content: 'We bring together a network of energy suppliers and compare their offered rates on different electricity and gas plans. We put the power in your hands to decide which price is the best and provide the <a class="text-primary underline" href="/energy/faqs-switching-providers/">switching service</a> free of charge. You may be asked to provide some documents, but we will arrange the connection and send you any relevant information. It is easy!'
  },
  {
    title: 'How much does it cost to switch energy suppliers?',
    content: 'At Compare Club, we do not charge any fees for the <a href="/energy/quote/form/step1/" class="text-primary underline">use of our comparison tools</a> or for speaking to our energy consultants. However, your current supplier might have contractual terms that include exit fees.'
  },
  {
    title: 'How long does it take to switch energy suppliers?',
    content: 'Comparing suppliers and arranging the switch does not take long, but unless you have a smart-meter it can be up to three months before the switch takes place. Usually, you will need a final meter reading to close out your existing account, which tends to occur every three months. <br><br> If you are further along in your billing cycle, then the switch may happen earlier. It may also be quicker if you have a smart meter installed, which provides up-to-the-minute meter readings.'
  },
  {
    title: 'Do electricity prices vary from state to state?',
    content: 'Yes, they do. Depending on the rates set by your state’s suppliers, electricity prices can vary significantly. This is due to different sources of energy generation and investment in infrastructure, as well as supply and demand. <br><br> There are several factors that influence prices in the short term and long term. Some of these factors are out of consumers’ hands, which is why the ability to compare suppliers is such a powerful money-saving tool.'
  },
  {
    title: 'Why should I switch energy suppliers?',
    content: 'By comparing energy plans, you can easily find which suppliers are cheapest on the market, allowing you to make the switch and save big on your electricity and gas bill. <br><br> People choose to switch for several reasons, but mostly it is to find a more affordable price. Other reasons include <a href="/energy/moving-house/" class="text-primary underline">moving to a new house</a>, adding a renewable energy source, or because you are not happy with the service from your current supplier.'
  },
  {
    title: 'Why are there so many energy suppliers?',
    content: 'Due to the government’s easing of laws regarding competition policy in energy markets, Australia has seen a <a href="/energy/top-10-companies/" class="text-primary underline">mass increase in suppliers</a>, meaning greater competition and more competitive rates for Australian consumers. In general, the more suppliers there are in the market, the lower the tariffs.'
  },
  {
    title: 'What is an energy tariff?',
    content: 'A tariff is the amount you are charged for energy as part of your contract. You are likely to see two types of tariffs on your bill: a supply charge and a variable (or usage) charge. The supply charge is a type of service charge that covers the <a href="/energy/electricity-house/" class="text-primary underline">cost of supplying energy to your house</a>. The variable charge changes depending on how much energy you use.'
  },
  {
    title: 'I am signed up to a solar feed-in tariff. Will I still be eligible when I switch suppliers?',
    content: 'This will depend on your&nbsp;<a href="/energy/solar-save-energy-money/" class="text-primary underline">solar scheme</a>. Regulations vary from state to state and between suppliers; your solar feed-in tariff may be linked to your address or to the account with your supplier. <br><br> Contact your supplier to find out if you can bring your feed-in tariff to a new supplier. When comparing different plans, you may even find that a different supplier offers a better deal on feed-in tariffs.'
  },
  {
    title: 'I have a business. Can you still help me switch energy suppliers?',
    content: 'Of course, we can!&nbsp;Energy costs are a major component of most businesses and can be one of your biggest expenses. It makes sense to <a href="/energy/quote/form/step1/" class="text-primary underline">compare energy plans</a> so you can find a more affordable supplier and reduce your overall spending.'
  }
]
